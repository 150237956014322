import React, { useState, useMemo, useEffect } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Button,
  Box,
  Typography,
  TableSortLabel,
} from '@mui/material'
import { CSVLink } from 'react-csv'
import { getData } from '../utils/api'
import { useAuth } from '../hooks/useAuth';
import { dateTimeToString, getLocalDateTime } from '../utils/datef';
import { format } from 'date-fns';
import { toDate } from 'date-fns-tz';

// Sample data
const initialData = [
  {
    creación: "cargando...",
    cancha: "cargando...",
    deporte: "cargando...",
    día: "cargando...",
    minutos: "cargando...",
    nombre: "cargando...",
    "Precio reserva": "cargando...",
    cancelado: "cargando...",
    pagado: "cargando...",
    devuelto: "cargando...",
},

]

function Insights() {
  const [data, setData] = useState(initialData)
  const [sortConfig, setSortConfig] = useState({ key: 'created_dttm', direction: 'asc' })
  const [filters, setFilters] = useState({})
  
  const API_URL = process.env.REACT_APP_API_URL;
  const user = useAuth().userData;

  useEffect(() => {
    const fetchReservations = async () => {
      try {
        const response = await getData(`${API_URL}/reservations/`, user.token);
        setData(response.results.map((reservation) => {
          const localStartDateTime = toDate(reservation.start_dttm, 
            { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone })
          const localCreatedDateTime = toDate(reservation.created_dttm, 
            { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone });
          return {
            key: reservation.id,
            creación: format(localCreatedDateTime, 'yyyy-MM-dd HH:mm'),
            cancha: reservation.court_name,
            deporte: reservation.sport,
            día: format(localStartDateTime, 'dd MMM HH:mm'),
            minutos: Math.round((new Date(reservation.end_dttm) - new Date(reservation.start_dttm))/1000/60),
            nombre: reservation.owner_name.replace('-', ' '),
            "Precio reserva": reservation.price,
            cancelado: reservation.is_cancelled ? "Sí" : "No",
            pagado: reservation.is_paid ? "Sí" : "No",
            devuelto: reservation.is_refunded ? "Sí" : "No"
          };
        }));
      } catch (error) {
        console.error('Error fetching reservations:', error);
      } 
    };
    fetchReservations();
  }, [API_URL, user.token]);

  const handleSort = (key) => {
    let direction = 'asc'
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc'
    }
    setSortConfig({ key, direction })
  }

  const handleFilterChange = (key, value) => {
    setFilters(prev => ({ ...prev, [key]: value }))
  }

  const filteredAndSortedData = useMemo(() => {
    let filteredData = data.filter(item =>
      Object.entries(filters).every(([key, value]) => {
        if (!value) return true
        const itemValue = String(item[key]).toLowerCase()
        return itemValue.includes(value.toLowerCase())
      })
    )

    return filteredData.sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === 'asc' ? -1 : 1
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === 'asc' ? 1 : -1
      }
      return 0
    })
  }, [data, sortConfig, filters])

  const filteredCSVData = filteredAndSortedData.map(({ key, ...rest }) => rest);


  return (
    <Box sx={{ width: '100%', padding: 3 }}>
      <Typography variant="h4" gutterBottom>
        Reservas
      </Typography>
      <Box sx={{ mb: 2 }}>
        <CSVLink data={filteredCSVData} filename="insights.csv">
          <Button variant="contained" color="primary">
            Download as CSV
          </Button>
        </CSVLink>
      </Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="insights table">
          <TableHead>
            <TableRow>
              {Object.keys(initialData[0]).map(key => (
                <TableCell key={key}>
                  <TableSortLabel
                    active={sortConfig.key === key}
                    direction={sortConfig.key === key ? sortConfig.direction : 'asc'}
                    onClick={() => handleSort(key)}
                  >
                    {key.charAt(0).toUpperCase() + key.slice(1)}
                  </TableSortLabel>
                  <TextField
                    size="small"
                    placeholder={`Filter ${key}`}
                    value={filters[key] || ''}
                    onChange={e => handleFilterChange(key, e.target.value)}
                    sx={{ mt: 1 }}
                  />
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredAndSortedData.map(row => (
              <TableRow key={row.key} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                {Object.entries(row).filter(([key]) => key !== 'key').map(([key, value]) => (
                  <TableCell key={`${row.key}-${key}`}>{value}</TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default Insights