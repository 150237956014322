import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
//import Link from '@mui/material/Link';
import {Link} from "react-router-dom";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { postAPI } from '../utils/api';
import { useState } from "react";
import { useAuth } from "../hooks/useAuth";
import { validateEmail, validatePhone } from '../utils/formValidators';
import CircularProgress from '@mui/material/CircularProgress';
import PasswordField from '../Components/PasswordField';


function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

const errorMessages = {
  "This password is too short. It must contain at least 8 characters." : "La contraseña es muy corta. Debe contener al menos 8 caracteres.",
  "This password is too common." : "La contraseña es muy común.",
  "This password is entirely numeric." : "La contraseña es completamente numérica.",
  "User with this phone number already exists.": "El número de teléfono ingresado ya está registrado.",
  "A user with that username already exists.": "El email ingresado ya está registrado.",
  // Add more mappings as needed
};

const translateErrorMessage = (message) => {
  return errorMessages[message] || 'Error desconocido. Inténtalo de nuevo.';
};

export default function SignUp() {

  const [response, setResponse] = useState("");
  const { login } = useAuth();

  const [isFilled, setisFilled] = useState(false);

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    phone: '+56',
  });

  const [isLoading, setIsLoading] = useState(false);

  const [emailError, setEmailError] = useState(null);
  const [phoneError, setPhoneError] = useState(null);

  const handleChange = (event) => {
    const { name, value } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handlePhoneChange = (event) => {
      const { value } = event.target;
      if (value.startsWith('+56')) {
        setFormData((prevData) => ({
          ...prevData,
          phone: value,
        }));
      }
      if (value.length === 12) {
        validatePhone(value, "CL") ? setPhoneError(null) : 
        setPhoneError('Número de teléfono inválido, ejemplo: +56912345678');
      }
    };

    React.useEffect(() => {
        setisFilled(
            formData.email.length > 0
            && formData.password.length > 0
            && formData.firstName.length > 0
            && formData.lastName.length > 0
            && formData.phone.length > 0
        );
    }, [formData]);

    const handleBlurEmail = () => {
      validateEmail(formData.email) ? setEmailError(null) :
        setEmailError('Email inválido');
    };

    const handleBlurPhone = () => {
      validatePhone(formData.phone, "CL") ? setPhoneError(null) : 
        setPhoneError('Número de teléfono inválido, ejemplo: +56912345678');
    };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const API_URL = process.env.REACT_APP_API_URL;

    const responseData = await postAPI(`${API_URL}/users/`,
      { username: formData.email, 
        password: formData.password,
        email: formData.email,
        first_name: formData.firstName+'-'+formData.lastName,
        last_name: formData.phone,
        user_type: 'seller'
      });
    
    if (Array.isArray(responseData?.username) || Array.isArray(responseData?.last_name)) {
      const userNameError = responseData?.username?.[0] ?? null;
      const lastNameError = responseData?.last_name?.[0] ?? null;

      if (userNameError || lastNameError) {
        setResponse((!!userNameError ? translateErrorMessage(userNameError) : '')
          + ' ' + (!!lastNameError ? translateErrorMessage(lastNameError) : ''));
      }
    } else { if (responseData?.username === formData.email) {
        const usernamelogin = formData.email;
        await login({ usernamelogin }, responseData);
      } else {
        setResponse("Error al registrar usuario");
      }
    }
    
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Creación de cuenta
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="given-name"
                  name="firstName"
                  onChange={handleChange}
                  required
                  fullWidth
                  id="firstName"
                  label="Nombre"
                  autoFocus
                  value={formData.firstName}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  onChange={handleChange}
                  fullWidth
                  id="lastName"
                  label="Apellido"
                  name="lastName"
                  autoComplete="family-name"
                  value={formData.lastName}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="phone"
                  label="Número de teléfono"
                  type="tel"
                  id="phone"
                  autoComplete="tel"
                  value={formData.phone}
                  onChange={handlePhoneChange}
                  onBlur={handleBlurPhone}
                  error={!!phoneError}
                  helperText={phoneError}
                  inputProps={{
                    maxLength: 12,
                    minLength: 12,
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  required
                  onChange={handleChange}
                  fullWidth
                  id="email"
                  label="Email"
                  name="email"
                  autoComplete="email"
                  onBlur={handleBlurEmail}
                  error={!!emailError}
                  helperText={emailError}
                  value={formData.email}
                />
              </Grid>
              
              <PasswordField handleChange={handleChange} />
              
              
            </Grid>
                
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={isLoading || !isFilled || !!emailError || !!phoneError}
            >
              {isLoading ? (
                  <>
                    <CircularProgress size={24} color="inherit" sx={{ mr: 1 }} />
                    Creando cuenta
                  </>
                ) : (
                  'Crear cuenta'
                )}
            </Button>
            <Typography variant="body2" color="red" align="center">
                  {response}
                </Typography>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link to="/signin" variant="body2">
                  Ya tienes una cuenta? Sign in
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 5 }} />
      </Container>
    </ThemeProvider>
  );
}