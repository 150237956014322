import IconButton from '@mui/material/IconButton';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Grid, TextField, Box, Alert, MenuItem } from '@mui/material';
import { useState, useEffect } from "react";
import { useAuth } from "../hooks/useAuth";
import { postDataToken } from '../utils/api';
import { nowToString } from '../utils/datef';
import TextInputGoogleMaps from './TextInputGoogleMaps';
import { bankAccountTypes, chileanBanks, validateEmail, validateNumber, validatePhone } from '../utils/formValidators';


const CreateClub = ({ setSeverity, setMessage, setActivateAlert }) => {
    const user = useAuth().userData;
    const { changeUserData } = useAuth();
    
    const [form, setForm] = useState(
        {
            club_email: user.username,
            club_number: user.last_name,
            country: "Chile",
            created_date: nowToString()
        }
        );

       
    const [canCreate, setCanCreate] = useState(false);

    const [enableForm, setEnableForm] = useState(false);

    // not used, only to avoid error on TextInputGoogleMaps
    const [clubChange, setClubChange] = useState(false); 

    const [formatValid, setFormatValid] = useState(false);
    const [arrayAlerts, setArrayAlerts] = useState([]);

    
    // on form change, enable or disable create club button based on form fields
    useEffect(() => {
        setCanCreate(form.name && form.address &&
            form.address && form.bank && form.bank_account_type &&
            form.bank_account_number && form.club_email &&
            form.club_number
        );

        setFormatValid(
            validateEmail(form.club_email) &&
            validatePhone(form.club_number,"CL") &&
            validateNumber(form.bank_account_number)
        )

        setArrayAlerts(
            [
                [!validateEmail(form.club_email), "Email inválido"],
                [!validatePhone(form.club_number,"CL"), "Número de teléfono inválido, ej: +56912345678"],
                [!validateNumber(form.bank_account_number), "Número de cuenta bancaria debe ser solo números"]
            ].reduce((acc, alert) => {
                if (alert[0]) {
                return [...acc, alert[1]];
                }
                return acc;
        }, [])
        );
    }, [form])


    const handleClick = () => {
        setEnableForm(true);
      };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const API_URL = process.env.REACT_APP_API_URL;
        const newClub = await postDataToken(`${API_URL}/clubs/`, user.token, form);
       
        if (newClub.url) {
            setSeverity("success");
            setMessage("Club creado con éxito");
        } else {
            setSeverity("error");
            setMessage("Error al crear club");
        }
    
        setActivateAlert(true);
        changeUserData(
            {
              ...user,
              clubs: [{...newClub}]
            }
            );
    }
    
    return (
        
        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
            
            {!enableForm ? // when create club is clicked the button disappear
            <IconButton
              fullWidth
              variant="contained"
              color="primary"
              size='small'
              onClick={handleClick}
              sx={{ mt: 3, mb: 2 }}
            >
                <AddCircleIcon/>
                Crear Club
            </IconButton>
            : ""}
            { (enableForm) ? // if create button is pressed, form is displayed  
            <>
                <Grid container spacing={2}>
                <Grid item xs={12}>
                <TextField
                    required
                    fullWidth
                    onChange={(e) => {
                        setForm({
                        ...form,
                        name: e.target.value
                        });
                    }}
                    id="club_name"
                    label="Nombre del Club"
                    name="club_name"
                    autoComplete="club_name"
                    />
                </Grid>

                <TextInputGoogleMaps 
                setForm={setForm}
                form={form}
                setClubChange={setClubChange}
                />

                <Grid item xs={12}>
                    <TextField
                    required
                    fullWidth
                    onChange={(e) => {
                        setForm({
                        ...form,
                        club_email: e.target.value
                        });
                    }}
                    name="club_email"
                    label="Email Club"
                    type="club_email"
                    id="club_email"
                    autoComplete="club_email"
                    defaultValue={user.username}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                    required
                    fullWidth
                    onChange={(e) => {
                        setForm({
                        ...form,
                        club_number: e.target.value
                        });
                    }}
                    name="club_phone"
                    label="Número de telefono del Club"
                    type="tel"
                    id="club_phone"
                    autoComplete="club_phone"
                    defaultValue={
                        user.last_name
                    }
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                    required
                    fullWidth
                    select
                    onChange={(e) => {
                        setForm({
                        ...form,
                        bank: e.target.value
                        });
                    }}
                    name="bank"
                    label="Banco"
                    type="bank"
                    id="bank"
                    autoComplete="bank"
                    >
                        {
                            chileanBanks.map((bank) => (
                            <MenuItem key={bank} value={bank}>
                                {bank}
                            </MenuItem>
                            ))
                        }
                    </TextField>
                </Grid>
                    
                <Grid item xs={12}>
                    <TextField
                    required
                    fullWidth
                    select
                    onChange={(e) => {
                        setForm({
                        ...form,
                        bank_account_type: e.target.value
                        });
                    }}
                    name="bank_account_type"
                    label="Tipo de cuenta"
                    type="bank_account_type"
                    id="bank_account_type"
                    autoComplete="bank_account_type"
                    >
                        {
                            bankAccountTypes.map((type) => (
                            <MenuItem key={type} value={type}>
                                {type}
                            </MenuItem>
                            ))
                        }
                    </TextField>
                </Grid>

                <Grid item xs={12}>
                    <TextField
                    required
                    fullWidth
                    onChange={(e) => {
                        setForm({
                        ...form,
                        bank_account_number: e.target.value
                        });
                    }}
                    name="bank_account_number"
                    label="Número de cuenta bancaria"
                    type="bank_account_number"
                    id="bank_account_number"
                    autoComplete="bank_account_number"
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                    required
                    fullWidth
                    onChange={(e) => {
                        setForm({
                        ...form,
                        bank_account_owner_rut: e.target.value
                        });
                    }}
                    name="bank_account_owner_rut"
                    label="Rut asociado a la cuenta bancaria"
                    type="bank_account_owner_rut"
                    id="bank_account_owner_rut"
                    autoComplete="bank_account_owner_rut"
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                    required
                    fullWidth
                    onChange={(e) => {
                        setForm({
                        ...form,
                        bank_account_owner_name: e.target.value
                        });
                    }}
                    name="bank_account_owner_name"
                    label="Nombre asociado a la cuenta bancaria"
                    type="bank_account_owner_name"
                    id="bank_account_owner_name"
                    autoComplete="bank_account_owner_name"
                    />
                </Grid>

                </Grid>
                
                <Grid container spacing={2}>    
                    <Grid item xs={12}/>
                    <Grid item xs={12}>
                    { 
                    arrayAlerts.map((alert) => (
                    <Alert severity="warning">{alert}</Alert>
                    ))
                    }
                    </Grid>
                </Grid>
                
                <IconButton
                type="submit"
                fullWidth
                variant="contained"
                disabled={!canCreate && !formatValid}
                color="primary"
                size='small'
                sx={{ mt: 3, mb: 2 }}
                >
                    <AddCircleIcon/>
                    Crear Club
                </IconButton>
            </>
           :""}
          </Box>
    
    )
}

export default CreateClub;
