import { TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from "@mui/material"
import { tomorrowToString } from "../utils/datef";

const DateFilter = ({ selectedDate, handleChange, handleCancel, handleConfirm, open, tempDate }) => {
    
    return (
        <>
        <TextField
                        margin="normal"
                        onChange={handleChange}
                        required
                        type="date"
                        id="selectDate"
                        name="selectDate"
                        value={selectedDate}
                        inputProps={{
                            min: tomorrowToString(),
                          }}
        />
        <Dialog
                open={open}
                onClose={handleCancel}
            >
                <DialogTitle>Tienes cambios no guardados</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        ¿Estás seguro de que quieres cambiar la fecha a {tempDate}?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={handleConfirm} color="primary" autoFocus>
                        Confirmar
                    </Button>
                </DialogActions>
            </Dialog>
            </>
        
    )
};

export default DateFilter;


