import React from 'react';
import { Button, Stack } from '@mui/material';
import SnackbarAlert from './SnackBarAlert';
import { updateData, deleteData, postDataToken, getData } from '../utils/api';
import { generateRandomString } from '../utils/random';
import { useAuth } from "../hooks/useAuth";
import { useState } from "react";
import ReservationCreateDialog from './ReservationCreateDialog';
import { set } from 'date-fns';
import { utcToLocal } from '../utils/datef';



export default function DayViewActions({ actions, setActions, courts, 
    setCourts, selectedDate, setLastActionIndex, selectedBlocks, 
    handleBlockAction, setReservations, setSelectedBlocks }) {
    
    const [activateAlert, setActivateAlert] = useState(false);
    const [severity, setSeverity] = useState("success");
    const [message, setMessage] = useState("default message");
    const [price, setPrice] = useState(0);

    const [open, setOpen] = useState(false);

    const user = useAuth().userData;
    const clubs = user.clubs ? [...user.clubs] : [];
    const { changeUserData } = useAuth();
    const API_URL = process.env.REACT_APP_API_URL;

    const handlePriceClick = async () => {
        for (const action of actions) {
            await handlePriceChange(action);
        }
        setActions([]);
    }

    const handlePriceChange = async (action) => {
        
        const court = courts.find(c => c.id === action.id);
    
        // if has already a price for that day we can change it or delete it if it is the same as original price
        if (court.prices.filter(p => p.start_date === action.actionDay).length > 0) {
            const oldPrices = court.prices.slice();
        
            const oldPriceId = oldPrices.find(p => p.start_date === action.actionDay).id;
            
            if (Math.trunc(action.newValue) !== Math.trunc(court.price)) {
                const newPrices = oldPrices.map((p) =>
                    (p.start_date === action.actionDay ?
                    { ...p, price: action.newValue } : p));

                const oldCourtState = courts.slice();
                const newCourtState = oldCourtState.map((court) => 
                                    (court.id === action.id ? 
                                    { ...court, prices: newPrices } : court));

                const body = { ...(newPrices.find(p => p.id === oldPriceId)),
                                last_update_date: new Date().toISOString()};
                                
                const updatedPrice = await updateData(`${API_URL}/prices/${oldPriceId}/`, user.token, body);

                if (updatedPrice) {
                    setCourts(prevCourts => newCourtState);
                
                    changeUserData(
                        {
                            ...user,
                            clubs: [
                                {...clubs[0],
                                    courts: newCourtState
                                }
                            ]
                        } 
                    );
                    setSeverity("success");
                    setMessage("Precio actualizado con éxito");
        
                } else {
                    setSeverity("error");
                    setMessage("Error al actualizar precio");
                }
                
                } else {
                const newPrices = oldPrices.filter((p) =>
                    (p.id !== oldPriceId)
                        );
                  
                const oldCourtState = courts.slice();
                const newCourtState = oldCourtState.map((court) => 
                                    (court.id === action.id ? 
                                    { ...court, prices: newPrices } : court));
                
                const deletedPrice = await deleteData(`${API_URL}/prices/${oldPriceId}/`, user.token);
                if (deletedPrice.status===204) {
                    setCourts(prevCourts => newCourtState);
                    changeUserData(
                        {
                            ...user,
                            clubs: [
                                {...clubs[0],
                                    courts: newCourtState
                                }
                            ]
                        } 
                    );
                    setSeverity("success");
                    setMessage("Precio actualizado con éxito");
        
                } else {
                    setSeverity("error");
                    setMessage("Error al actualizar precio");
                }
                }
            
        } else {
            // if there is no price for that day we can add it
            const body = {
                start_date: action.actionDay,
                end_date: action.actionDay,
                start_time: "00:00:01",
                end_time: "23:59:59",
                name: generateRandomString(10),
                price: action.newValue,
                court: court.url,
                last_update_date: new Date().toISOString(),
                created_date: new Date().toISOString(),
                description: "Sin descripción"
            }

            const newPrice = await postDataToken(`${API_URL}/prices/`, user.token, body);

            if (newPrice) {
                const oldCourtState = courts.slice();
                const newCourtState = oldCourtState.map((court) => 
                                    (court.id === action.id ? 
                                    { ...court, prices: [...court.prices, newPrice] } : court));

                setCourts(prevCourts => newCourtState);  
                changeUserData(
                    {
                        ...user,
                        clubs: [
                            {...clubs[0],
                                courts: newCourtState
                            }
                        ]
                    } 
                );
                setSeverity("success");
                setMessage("Precio actualizado con éxito");
            } else {
                setSeverity("error");
                setMessage("Error al actualizar precio");
            }

        }

        setActivateAlert(true);
        setLastActionIndex(null);
    }

    const timeToMinutes = (time) => {
        const [hours, minutes] = time.split(':').map(Number);
        return hours * 60 + minutes;
      };

    const sortBlocks = (blocks) => {
        return blocks.sort((a, b) => {
            return timeToMinutes(a.hour.split(" - ")[0]) - timeToMinutes(b.hour.split(" - ")[0]);
        });
    }

    const consecutiveBlocksValidator = () => {
        const sortedBlocks = [...sortBlocks(selectedBlocks)];
        
        return sortedBlocks.reduce((acc, block, index) => {
            if (index === 0) {
                return true;
            }
            const prevBlock = sortedBlocks[index - 1];
            const prevBlockEnd = timeToMinutes(prevBlock.hour.split(" - ")[1]);
            const currentBlockStart = timeToMinutes(block.hour.split(" - ")[0]);
            return prevBlockEnd === currentBlockStart;
        }, true);
    }


    const handleOpen = async () => {
        const courtIdSet = new Set();
        selectedBlocks.forEach(block => {
            courtIdSet.add(block.court_id);
        });
        const distinctCourtCount = courtIdSet.size;

        // if there is more than one court selected we can't make a reservation
        if (distinctCourtCount > 1) {
            setSeverity("error");
            setMessage("Solo puedes reservar en una cancha a la vez");
            setActivateAlert(true);
        } else { if (!consecutiveBlocksValidator()) {
            setSeverity("error");
            setMessage("Debes seleccionar bloques consecutivos");
            setActivateAlert(true);
        } else {
            setOpen(true);
            const fetchPrices = async () => {
                try {
                    const slot_time = selectedBlocks[0].hour.split(' - ')[0].padStart(5, '0');
                    const minutes = 30*selectedBlocks.length;
                    const court_id = selectedBlocks[0].court_id
                    const response1 = await getData(
                        `${API_URL}/available-courts/?slot_day=${selectedDate}&slot_time=${slot_time}&slot_length=${minutes}&court_id=${court_id}`,
                        user.token); 
                    console.log(response1[0].slots)
                    setPrice(response1[0].slots.find(interval =>
                        utcToLocal(interval.start_time,'time') === slot_time).price);
                } catch (error) {
                console.error('Error fetching prices:', error);
                } 
            }
            fetchPrices();
        }}
    }

    const handleDialogClose = (variable) => {
        setOpen(false);
        if (variable===true) {
            setSeverity("success");
            setMessage("Reserva creada con éxito");
            setActivateAlert(true);
        }
    }
        

    const handleClose = () => {
        setActivateAlert(false);
      }

    React.useEffect(() => {
        setActions([]);
    }
    , [selectedDate, setActions]);

    return (
        
                    <>
                    <Stack 
                        spacing={1} 
                        direction='column'
                        justifyContent="center"
                        
                    >
                        {                           
                            
                            (actions.length > 0) ? 
    
                                <Button 
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    size='small'
                                    onClick={handlePriceClick}
                                >
                                Guarda cambios de precio
                                </Button>
                                : ""
                        }
                        

                        {
                            (selectedBlocks.length > 0) ? 
                            <>
                            <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            size='small'
                            
                            onClick={handleOpen}
                            >
                                Reserva manual
                            </Button>

                            <ReservationCreateDialog
                                open={open}
                                onClose={handleDialogClose}
                                courts={courts}
                                setReservations={setReservations}
                                selectedDate={selectedDate}
                                selectedBlocks={[...sortBlocks(selectedBlocks)]}
                                setSelectedBlocks={setSelectedBlocks}
                                initialPrice={price}
                            />
    
                            <Button 
                                fullWidth
                                variant="contained"
                                color="primary"
                                size='small'
                                onClick={handleBlockAction}
                            >
                                Bloquear horarios seleccionados
                            </Button>
                            </>
                            : ""
                        }

                    </Stack>
            <SnackbarAlert
                        open={activateAlert}
                        onClose={handleClose}
                        severity={severity}  // Puedes cambiar a "error", "warning", "info"
                        message={message}
                        ms={6000}  // Duración en milisegundos (6000ms = 6s)
                        vertical='bottom'
                        horizontal='right'
                    />
                    </>
                
    )
}
