import React from 'react';
import { Card, CardContent, Typography, Avatar, Grid, List, ListItem, ListItemText } from '@mui/material';
import { useAuth } from "../hooks/useAuth";

const SellerInfo = () => {
    const userjson = useAuth().userData
  return (
    <Grid item xs={12}>
    
        <Card sx={{ maxWidth: 345, margin: 'auto', mt: 3, display: 'flex',}}>
        <CardContent>
            <Grid container spacing={2} alignItems="center">
            <Grid item>
                <Avatar alt={userjson.name} src={''} sx={{ width: 56, height: 56 }} />
            </Grid>
            <Grid item xs>
                <Typography variant="h5" component="div">
                {userjson.first_name.replace("-", " ")}
                </Typography>
            </Grid>
            </Grid>
            <List>
            <ListItem>
                <ListItemText primary="Email" secondary={userjson.username} />
            </ListItem>
            <ListItem>
                <ListItemText primary="Phone Number" secondary={userjson.last_name} />
            </ListItem>
            </List>
        </CardContent>
        </Card>
    
    </Grid>
  );
};

export default SellerInfo;