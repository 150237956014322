import React, { useRef } from "react";
import {
  APIProvider,
  useMapsLibrary,
} from "@vis.gl/react-google-maps";

import { useState, useEffect } from "react";

import { Grid, TextField } from "@mui/material";


const PlaceAutocomplete = ({ onPlaceSelect, address }) => {
    const [placeAutocomplete, setPlaceAutocomplete] = useState(null);
    const inputRef = useRef(null);
    const places = useMapsLibrary("places");
  
    useEffect(() => {
      if (!places || !inputRef.current) return;
  
      const options = {
        fields: ["geometry", "name", "formatted_address"],
      };
  
      setPlaceAutocomplete(new places.Autocomplete(inputRef.current, options));
    }, [places]);
    useEffect(() => {
      if (!placeAutocomplete) return;
  
      const listener = placeAutocomplete.addListener("place_changed", () => {
        const place = placeAutocomplete.getPlace();
        onPlaceSelect(place);
      });

      return () => listener.remove();
    }, [onPlaceSelect, placeAutocomplete]);
    
    return (
      <Grid item xs={12}>
      <TextField
                  inputRef={inputRef}
                  required
                  fullWidth
                  name="address"
                  label="Dirección"
                  type="address"
                  id="address"
                  autoComplete="address"
                  defaultValue={address}
                />
      </Grid>
    );
  };

const TextInputGoogleMaps = ({ form, setForm, setClubChange }) => {
  
  const handlePlaceSelect = (place) => {
    const formattedAddress = place?.formatted_address;

    setForm({
      ...form,
      address: formattedAddress,
    });
    setClubChange(true);
  };


  return (
    
    <APIProvider apiKey={process.env.REACT_APP_API_KEY}>
      
        <PlaceAutocomplete 
          onPlaceSelect={handlePlaceSelect}
          address={form.address} 
        />
      
    </APIProvider>
  );

}

export default TextInputGoogleMaps;