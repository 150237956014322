import Grid from '@mui/material/Grid';
import SellerInfo from '../Components/SellerInfo';
import MyClub from '../Components/MyClub';
import ChangePassForm from '../Components/ChangePassForm';

export default function Profile() {
    
    return (
            <Grid item xs={12} md={12} lg={12}>
                <SellerInfo />
                <br></br>
                <ChangePassForm />
                <br></br>
                <MyClub />
            </Grid>
            
    )
}
