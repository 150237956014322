import React from 'react';
import ReactDOM from 'react-dom/client';

import './index.css';
import reportWebVitals from './reportWebVitals';
import { sendToVercelAnalytics } from './vitals';

import { RouterProvider } from 'react-router-dom';
//import {createBrowserRouter, RouterProvider, redirect, BrowserRouter} from "react-router-dom"
//import AuthFlow from './routes/AuthFlow'
//import MyClubs from './routes/MyClubs';
//import { GoogleOAuthProvider } from '@react-oauth/google';
import { SpeedInsights } from "@vercel/speed-insights/react"

import { router } from "./App";


ReactDOM.createRoot(document.getElementById("root")).render(
 // <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID} >
    <>
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
    <SpeedInsights />
    </>
  //</GoogleOAuthProvider>
);


reportWebVitals(sendToVercelAnalytics);
