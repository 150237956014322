
import React, { useState } from 'react'
import { 
    Typography, 
    Card, 
    CardContent, 
    Button, 
    Dialog, 
    DialogTitle, 
    DialogContent,
    DialogActions,
    TextField, 
    Grid,
    IconButton,
    Alert
  } from '@mui/material'
  import DeleteIcon from '@mui/icons-material/Delete'
  import AddIcon from '@mui/icons-material/Add'
  import 'react-big-calendar/lib/css/react-big-calendar.css'
import { updateData } from '../utils/api'
import { useAuth } from '../hooks/useAuth'

export default function EditPriceConfig({ configurations, setConfigurations, 
    setAlertSeverity, setAlertMessage, 
    setOpenAlert, selectedSport, data }) {
    const [selectedConfig, setSelectedConfig] = useState(null)
    const [openDialog, setOpenDialog] = useState(false)
    const [intervals, setIntervals] = useState([])
      
    const [saveAlerts, setSaveAlerts] = useState([])

    const handleConfigEdit = (config) => {
        setSelectedConfig(config)
        setOpenDialog(true)
        setIntervals(config.intervals)
    }


    const API_URL = process.env.REACT_APP_API_URL;
    const user = useAuth().userData;

    const handleSave = () => {

        const messages = saveValidationMessages()
        if (messages.length > 0) {
            setSaveAlerts(messages)
            return
        } else {
          setSaveAlerts([])
        }

        const updatePriceConfigurations = async () => {
          // on render fetch the price configurations for the selected sport
          try {
            let response = null;
            // one change on the selected configuration is applied to all courts
            for (let i = 0; i < data.length; i++) {
                if (data[i].type === selectedConfig.type) {
                    response = await updateData(`${API_URL}/priceconfigurations/${data[i].id}/`, user.token, {
                        intervals: intervals
                    });
                }
            }
            if (response) {
              setConfigurations(prev => {
                  const newConfigurations = prev.map(config => {
                      if (config.id === selectedConfig.id) {
                          return {
                              ...config,
                              intervals: intervals
                          }
                      }
                      return config
                  })
                  return newConfigurations
              })
              setOpenDialog(false)
              if (messages.length === 0) {
                setAlertSeverity('success')
                setAlertMessage('Configuración guardada correctamente')
                setOpenAlert(true)
            }
            }
          } catch (error) {
          console.error('Error updating price configurations:', error);
          } 
      };
        updatePriceConfigurations();


        
        
    }

    const handleIntervalChange = (index, field, value) => {
        const newIntervals = [...intervals]
        newIntervals[index][field] = value
        setIntervals(newIntervals)
    }

    const addInterval = () => {
        setIntervals([...intervals, { start: '', end: '', price: '' }])
    }

    const removeInterval = (index) => {
        const newIntervals = intervals.filter((_, i) => i !== index)
        setIntervals(newIntervals)
    }

    const saveValidationMessages = () => {
        if (intervals.length === 0) {
            return []
        }
        const timeDiffPositive = intervals.every(interval => interval.start < interval.end)
        const pricesPositive = intervals.every(interval => interval.price > 0 )
        const pricesNotNaN = intervals.every(interval => !isNaN(interval.price) )
        const conflictTimeIntervals = intervals.every((interval, index) => {
            return intervals.slice(index + 1).every((otherInterval) => {
                return interval.start >= otherInterval.end || interval.end <= otherInterval.start
            })
        })
        const messages = []
        if (!timeDiffPositive) {messages.push('Todos los intervalos de tiempo deben ser positivos')}
        if (!pricesPositive) {messages.push('Todos los precios deben ser positivos')}
        if (!pricesNotNaN) {messages.push('Todos los precios deben ser números')}
        if (!conflictTimeIntervals) {messages.push('Los intervalos de tiempo no deben superponerse')}
        return messages

    }
    return (
        <>
    <Grid container spacing={2} sx={{marginTop: 1}}>
        {configurations.map((config) => (
          <Grid item xs={6} sm={6} md={3} key={config.id}>
            <Card>
              <CardContent>
                <Typography>
                    {config.name} <span style={{ color: config.color }}>$</span>
                </Typography>
                <Button 
                  variant="contained" 
                  size="small"
                  color="primary"
                  onClick={() => handleConfigEdit(config)}
                >
                  Editar
                </Button>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Dialog open={openDialog} onClose={ () => setOpenDialog(false) } fullWidth maxWidth="md">
        <DialogTitle>
          Editar {selectedConfig?.name}: Se aplicará a todas las canchas de {selectedSport} que tengas
        </DialogTitle>
        <DialogContent>
          <Typography variant="h6" gutterBottom style={{ marginTop: '20px' }}>
            Precios e intervalos de tiempo
          </Typography>
          {intervals.map((interval, index) => (
            <Grid container spacing={2} key={index} alignItems="center">
              <Grid item xs={12} sm={3}>
                <TextField
                  label="Hora Inicio"
                  type="time"
                  value={interval.start}
                  onChange={(e) => handleIntervalChange(index, 'start', e.target.value)}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ step: 300 }}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  label="Hora Fin"
                  type="time"
                  value={interval.end}
                  onChange={(e) => handleIntervalChange(index, 'end', e.target.value)}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ step: 300 }}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  label="Precio por hora"
                  type="number"
                  value={interval.price}
                  onChange={(e) => handleIntervalChange(index, 'price', e.target.value)}
                  fullWidth
                  InputProps={{ startAdornment: '$' }}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <IconButton onClick={() => removeInterval(index)} aria-label="delete interval">
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </Grid>
          ))}
          <Button
            startIcon={<AddIcon />}
            onClick={addInterval}
            style={{ marginTop: '10px' }}
          >
            Agregar intervalo
          </Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={ () => setOpenDialog(false) } color="primary">
            Cancelar
          </Button>
          <Button onClick={handleSave} color="primary" variant="contained">
            Guardar
          </Button>
        </DialogActions>
        {saveAlerts.map((message, index) => (
            <Alert key={index} severity="warning">
                {message}
            </Alert>
            ))
        }
      </Dialog>
        </>
    )
}
