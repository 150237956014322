import { Route, createBrowserRouter, createRoutesFromElements, defer, Navigate } from "react-router-dom";
import SignIn from "./pages/SignIn";
import SignUp from "./pages/SignUp";
import PassRecovery from "./pages/PassRecovery";
import Dashboard from "./pages/Dashboard";
import Home from "./pages/Home";
import TicTacToe from "./pages/Test";
import ErrorPage from './pages/error-page';

import "./App.css";
import { ProtectedRoute } from "./Components/ProtectedRoute";
import { AuthLayout } from "./Components/AuthLayout";

import Profile from "./pages/Profile";
import Courts from "./pages/Courts";
import Insights from "./pages/Insights";
import DayView from "./pages/DayView";
import MassiveActions from "./pages/MassiveActions";


// ideally this would be an API call to server to get logged in user data
const getUserData = () =>
  new Promise((resolve) =>
    setTimeout(() => {
      const user = window.localStorage.getItem("userData");
      resolve(user);
    }, 3000)
  );


// Add Error Page as Route prop: errorElement = {<ErrorPage />}

export const router = createBrowserRouter(
    createRoutesFromElements(
        <Route element={<AuthLayout />}
            loader={() => defer({ userPromise: getUserData()})}
            errorElement = {<ErrorPage />}      
        >
            
            <Route path="/signin" element={<SignIn />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/recovery/:uid/:token"
                loader={({ params }) => {
                    return {params}
                  }}
                element={<PassRecovery />} 
            />
            <Route
            path="/dashboard"
            element={
                <ProtectedRoute>
                    <Dashboard />
                </ProtectedRoute>
            }
            />
            <Route
                path="/"
                element={
                    <ProtectedRoute>
                        <Home />
                    </ProtectedRoute>
                }
                  
            >
                <Route index element={<Navigate to="courts" replace />} /> {/* Redirección predeterminada */}
                <Route
                    path="courts"
                    element ={<ProtectedRoute><Courts /></ProtectedRoute>}
                />

                <Route
                    path="dayview"
                    element ={<ProtectedRoute><DayView /></ProtectedRoute>}
                />

                <Route
                    path="insights"
                    element ={<ProtectedRoute><Insights /></ProtectedRoute>}
                />

                <Route
                    path="massiveactions"
                    element ={<ProtectedRoute><MassiveActions /></ProtectedRoute>}
                />

                <Route
                    path="profile"
                    element ={<ProtectedRoute><Profile /></ProtectedRoute>}
                />
            </Route>
            <Route
            path="/test"
            element={
                <ProtectedRoute>
                    <TicTacToe />
                </ProtectedRoute>
            }
            />
        </Route>
    )
);


