import React, { useState } from 'react';
import CourtForm from '../Components/CourtForm';
import { Container, IconButton, Box, Typography, Alert, Button } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useAuth } from "../hooks/useAuth";
import { nowToString } from '../utils/datef';
import { postDataToken, updateData } from '../utils/api';
import { formatCurrency } from '../utils/currency';

const Courts = () => {
    const { changeUserData } = useAuth();
    const user = useAuth().userData;
    const clubs = user.clubs ? [...user.clubs] : [];
    
    const initCourts = clubs.length>0 ? 
        [...clubs[0].courts] : []

    initCourts.sort((a, b) => a.id - b.id);
    const [courts, setCourts] = useState(initCourts);
    const [editingCourt, setEditingCourt] = useState(null);
    const [showForm, setShowForm] = useState(false);
  
    const handleSave = async (courtData) => {

        const API_URL = process.env.REACT_APP_API_URL;

        if (editingCourt) {    
            
            const oldCourtState = courts.slice();
            const newCourtState = oldCourtState.map((court) => 
                                    (court.id === editingCourt.id ? 
                                    { ...court, ...courtData } : court));
            setCourts(newCourtState);
            
            
            const body = {...editingCourt, ...courtData}
            const updatedCourt = await updateData(`${API_URL}/courts/${editingCourt.id}/`, user.token, body);
            
            if (updatedCourt) {
                changeUserData(
                    {
                        ...user,
                        clubs: [
                            {...clubs[0],
                                courts: newCourtState
                            }
                        ]
                    } 
                );
            }

            setEditingCourt(null);

        } else {
            const body = {
                ...courtData,
                created_date: nowToString(),
                club: clubs[0].url,
            };

            const newCourt = await postDataToken(
                `${API_URL}/courts/`, user.token, 
                body
                );
            
            if (newCourt) {
                setCourts((prevCourts) => [...prevCourts, { ...newCourt }]);
                //change database
    
                
                //save data also with refresh page
                changeUserData(
                    {
                        ...user,
                        clubs: [
                        {...clubs[0],
                            courts: [...courts, {...newCourt}
                            ]
                        }
                        ]
                    }
                    );
            }
            

        }

        setShowForm(false); //hide form

    };

    const handleCancel = () => {
        setEditingCourt(null);
        setShowForm(false);
    };

    const handleEdit = (court) => {
        setEditingCourt(court);
        setShowForm(true);
        setTimeout(() => {
            window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to the top of the page smoothly
        }, 0);
    };

    return (
        <Container>
        <Box display="flex" justifyContent="center" alignItems="center" mt={4}>
            {clubs.length>0 ? //only can add courts if the user has a Club
                <IconButton size="small" color="primary" onClick={() => setShowForm(true)}>
                <AddCircleIcon size="small" />
                    <Typography variant="h6" ml={2}>Agregar Cancha</Typography>
                </IconButton>
                :
                <Alert variant="outlined" severity="warning">
                    Debes crear un Club en la sección Mi Perfil para agregar canchas
                </Alert> 
            }
        </Box>
        {showForm && (
            <CourtForm
            initialData={editingCourt || {}}
            onSubmit={handleSave}
            onCancel={handleCancel}
            courts={courts}
            />
        )}
        <div style={{ marginTop: 32 }}>
            <Typography variant="h5" gutterBottom>Canchas</Typography>
            {courts.map((court) => (
            <Box 
                key={court.id} 
                mb={2} 
                p={2} 
                border={1} 
                borderRadius={4} 
                borderColor="grey.300"
                sx={{ backgroundColor: 'white' }}  // Aquí cambiamos el color de fondo
            >
                <Typography variant="h6">{court.name}</Typography>
                <Typography>Deporte: {court.sport}</Typography>
                <Typography>Disponible para arriendo: {court.is_available ? "Sí" : "No"}</Typography>
                <Button variant="outlined" color="primary" onClick={() => handleEdit(court)}>
                Editar
                </Button>
            </Box>
            ))}
        </div>
        </Container>
    );
};

export default Courts;
