import React, { useState, useEffect } from 'react';
import { useAuth } from '../hooks/useAuth';
import { postDataToken } from '../utils/api';
import { formatCurrency } from '../utils/currency';
import { 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions, 
  Button, 
  TextField, 
  Typography
} from '@mui/material';
import { format, formatISO } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';


export default function ReservationCreationDialog({ open, onClose, courts, 
    setReservations, selectedDate, selectedBlocks, setSelectedBlocks, initialPrice }) {
    const [selectedCourt, setSelectedCourt] = useState(selectedBlocks[0].court_id);
    const [mail, setMail] = useState('');
    const [price, setPrice] = useState(initialPrice);
    const [displayPrice, setDisplayPrice] = useState(formatCurrency(initialPrice.toString(), 'es-CL', 'currency','CLP',0,0));
        
    const API_URL = process.env.REACT_APP_API_URL;
    const { userData } = useAuth();


    useEffect(() => {
      setPrice(initialPrice);
      setDisplayPrice(formatCurrency(String(initialPrice), 'es-CL', 'currency', 'CLP', 0, 0));
    }, [initialPrice]);

  // only for price formatting we add handleBlur and handleFocus
  const handleBlur = () => {
    setDisplayPrice(formatCurrency(displayPrice, 'es-CL', 'currency','CLP',0,0));
};

const handleFocus = () => {
    setDisplayPrice(price);
};

  const handleClose = (e) => {
    e.stopPropagation();
    onClose();
    setPrice(1);
  };

  const validatePrice = (price) => {
    // only numbers
    if (!/^\d+$/.test(price)) {
      return false;
    }
    // only positive numbers
    if (parseInt(price) <= 0) {
      return false;
    }
    return true;
  };

  const handleReservationCreate = async (e) => {
    e.preventDefault();
    

    const startTime = selectedBlocks[0].hour.split(" - ")[0];
    const localStartDateTime = new Date(selectedDate + "T" + startTime.padStart(5, '0'));
  
    const endTime = selectedBlocks[selectedBlocks.length - 1].hour.split(" - ")[1];
    const localEndDateTime = new Date(selectedDate + "T" + endTime.padStart(5, '0'));


    const body = {
      start_dttm: format(toZonedTime(localStartDateTime, 'UTC'), 'yyyy-MM-dd HH:mm:ss'),
      end_dttm: format(toZonedTime(localEndDateTime, 'UTC'), 'yyyy-MM-dd HH:mm:ss'),
      court: selectedCourt,
      price: parseInt(price),
      is_cancelled: false
    };

    try {
      const response = await postDataToken(`${API_URL}/reservations/`, userData.token, body);
      setReservations(prevReservations => [...prevReservations, response]);
      onClose(true);
      setSelectedBlocks([]);
    } catch (error) {
      console.error('Error creating reservation:', error);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>Crear nueva reserva {selectedDate}</DialogTitle>
      <DialogContent>
        <Typography>Inicio: {selectedBlocks[0].hour.split(" - ")[0]}</Typography>
        <Typography>Fin: {selectedBlocks[selectedBlocks.length - 1].hour.split(" - ")[1]}</Typography>
        <Typography>Cancha: {courts.find(
          court => court.id === selectedBlocks[0].court_id).name}
        </Typography>
        <TextField
          label="Precio"
          value={displayPrice}
          onChange={(e) => {setPrice(e.target.value); setDisplayPrice(e.target.value);}}
          onFocus={handleFocus}
          onBlur={handleBlur}
          fullWidth
          margin="normal"
        />

        <TextField
          label="Mail jugador"
          value={mail}
          onChange={(e) => setMail(e.target.value)}
          fullWidth
          margin="normal"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button 
          onClick={handleReservationCreate} color="primary" variant="contained"
          disabled={!validatePrice(price) || mail === ''}
          >
          Create Reservation
        </Button>
      </DialogActions>
      
    </Dialog>
  );
}