import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import TimeBlock from './TimeBlock';
import { Typography } from '@mui/material';
import { timeToString, getLocalDateTime } from '../utils/datef';
import { toDate } from 'date-fns-tz';
import { format } from 'date-fns';

const DayColumn = ({ court,courtName,
        actionDay, blocks, selectedBlocks, handleBlockClick,
        handleUnblockClick, setLastActionIndex, reservations,
        mainReservations, setReservations
    }) => {  


    useEffect(() => {
        setLastActionIndex(null);
    }
    , [actionDay, setLastActionIndex]);

    // array starting from 6:00 and end at 24:00 with 30 min intervals
    const hours = Array.from({ length: 36 }, (_, i) => {
        const hour = Math.floor(i / 2) + 6;
        const nextHour = i % 2 === 0 ? hour : hour + 1;
        const minutes = i % 2 === 0 ? '00' : '30';
        const nextMinutes = i % 2 === 0 ? '30' : '00';
        const dateTimeString = `${actionDay}T${hour.toString().padStart(2,'0')}:${minutes}`;
        const localDateTime = toDate(dateTimeString, { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone });
        return {
        label: `${hour}:${minutes} - ${nextHour}:${nextMinutes}`,
        value: localDateTime,
        }
        ;
    });
 
    return (
    <Grid container direction="column" spacing={1}
        sx={{ minWidth: 140}}
    >
        <Typography variant="h6" gutterBottom>
            {courtName}
            </Typography>
        { // array starting from 6:00 and end at 24:00 with 30 min intervals
        
        hours.map(hour => { 
            const firstHour = hour.label.split(" - ")[0];
            const hours = firstHour.split(":")[0].padStart(2, '0');
            const minutes = firstHour.split(":")[1].padStart(2, '0');
  
            return (
            <TimeBlock
            key={hour.label}
            hour={hour}
            selected={selectedBlocks.includes(hour.label)}
            onClick={handleBlockClick}
            // check if the hour is blocked
            blocked={blocks.find(
                b => {
                    if (timeToString(getLocalDateTime(b.start_date + "T" + b.start_time)) === 
                    hours + ":" + minutes + ':00') {return true}
                    return false;
                }
                ) 
                !== undefined}
            reservation={
                reservations.find(
                r => {
                    const reservationLocalStartDateTime = toDate(r.start_dttm, 
                        { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone })
                    const reservationLocalEndDateTime = toDate(r.end_dttm,
                        { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone })
                    // the block must be within the reservation time
                    
                    return (hour.value.getTime() >= reservationLocalStartDateTime.getTime()
                        && hour.value.getTime() < reservationLocalEndDateTime.getTime())
                    
                })}
            id={court.id}
            onUnblockClick={handleUnblockClick}
            mainReservations={mainReservations}
            setReservations={setReservations}
            />
        )}
    )

        }
    </Grid>
    );
};

export default DayColumn;


 