
export const nowToString = () => {
    try {
        const hoy = new Date();
        const year = hoy.getFullYear();
        const month = String(hoy.getMonth() + 1).padStart(2, '0'); // Los meses en JavaScript van de 0 a 11, por eso sumamos 1
        const day = String(hoy.getDate()).padStart(2, '0');
        const fechaFormateada = `${year}-${month}-${day}`;
        return fechaFormateada;
    } catch (error) {
      console.error('Error:', error);
    }
  };

  export const nowDateTimeToString = () => {
    try {
        const hoy = new Date();
        const year = hoy.getFullYear();
        const month = String(hoy.getMonth() + 1).padStart(2, '0'); // Los meses en JavaScript van de 0 a 11, por eso sumamos 1
        const day = String(hoy.getDate()).padStart(2, '0');
        const fechaFormateada = `${year}-${month}-${day}T${hoy.getHours()}:${hoy.getMinutes()}:00`;
        return fechaFormateada;
    } catch (error) {
      console.error('Error:', error);
    }
  };
  
export const convertToMinutes = (time) => {
    const [hours, minutes] = time.split(':').map(Number);
    return hours * 60 + minutes;
  }

export const dateToString = (date) => {
  try {
      const hoy = date;
      const year = hoy.getFullYear();
      const month = String(hoy.getMonth() + 1).padStart(2, '0'); // Los meses en JavaScript van de 0 a 11, por eso sumamos 1
      const day = String(hoy.getDate()).padStart(2, '0');
      const fechaFormateada = `${year}-${month}-${day}`;
      return fechaFormateada;
  } catch (error) {
    console.error('Error:', error);
  }
};

export const dateTimeToString = (date) => {
  try {
      const hoy = date;
      const year = hoy.getFullYear();
      const month = String(hoy.getMonth() + 1).padStart(2, '0'); // Los meses en JavaScript van de 0 a 11, por eso sumamos 1
      const day = String(hoy.getDate()).padStart(2, '0');
      const hours = String(hoy.getHours()).padStart(2, '0');
      const minutes = String(hoy.getMinutes()).padStart(2, '0');
      const seconds = String(hoy.getSeconds()).padStart(2, '0');
      const fechaFormateada = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
      return fechaFormateada;
  } catch (error) {
    console.error('Error:', error);
  }
};

export const timeToString = (date) => {
  try {
      const hoy = date;
      const hours = String(hoy.getHours()).padStart(2, '0');
      const minutes = String(hoy.getMinutes()).padStart(2, '0');
      const timeFormateada = `${hours}:${minutes}:00`;
      return timeFormateada;
  } catch (error) {
    console.error('Error:', error);
  }
};

export const tomorrowToString = () => {
  const newDate = new Date();
  newDate.setDate(newDate.getDate() + 1);
  return dateToString(newDate);
};

// get datetime utc string and get local datetime
export const getLocalDateTime = (datetime) => {
  const date = new Date(datetime);
  const localDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000));
  return localDate;
};

// get datetime local string and get utc datetime
export const getUTCDateTime = (datetime) => {
  const date = new Date(datetime);
  const utcDate = new Date(date.getTime() + (date.getTimezoneOffset() * 60000));
  return utcDate;
};

// utc datetime to local datetime and return a string
export const utcToLocal = (utcDate, type) => {
  const date = new Date(utcDate);
  if (type === 'time') return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });
  if (type === 'date2') {
      // format like name of the week day in spanish, day and month
      const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
      return date.toLocaleDateString('es-CL', options);
  }
  if (type === 'date3') {
      // format like day, month, hour and minutes
      const options = { month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit', hour12: false };
      return date.toLocaleDateString('es-CL', options);
  }
  return date.toLocaleString();
};