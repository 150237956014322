import React from 'react';
import { Snackbar, Alert, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const SnackbarAlert = ({ open, onClose, severity, message, ms, vertical, horizontal }) => {
  return (
    <Snackbar
      open={open}
      autoHideDuration={ms}  // Duración en milisegundos (6000ms = 6s)
      onClose={onClose}
      anchorOrigin={{ vertical: vertical, horizontal: horizontal }}
    >
      <Alert
        onClose={onClose}
        severity={severity}
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={onClose}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default SnackbarAlert;
