import React, { useState, useEffect } from 'react';
import { TextField, Button, MenuItem, Grid, Box, Paper, Typography, Alert } from '@mui/material';
import { convertToMinutes } from '../utils/datef';
import { formatCurrency } from '../utils/currency';

const CourtForm = ({ initialData = {}, onSubmit, onCancel, courts }) => {
    const [formData, setFormData] = useState({
        id: initialData.id || null,
        name: initialData.name || '',
        sport: initialData.sport || '',
        price: initialData.price || "1",
        start_time: initialData.start_time || '10:00:00',
        end_time: initialData.end_time || '11:00:00',
        min_players: initialData.min_players || '',
        max_players: initialData.max_players || '', 
        is_available: initialData.is_available || false,
    });
    const [displayPrice, setDisplayPrice] = useState(formatCurrency(initialData.price || ''), 'es-CL', 'currency','CLP',0,0);

    const [isFormValid, setIsFormValid] = useState(false);
    const [arrayAlerts, setArrayAlerts] = useState([]);


    useEffect(() => {
        setFormData({
            id: initialData.id || null,
            name: initialData.name || '',
            sport: initialData.sport || '',
            price: initialData.price || '1',
            start_time: initialData.start_time || '10:00:00',
            end_time: initialData.end_time || '11:00:00',
            min_players: initialData.min_players || '',
            max_players: initialData.max_players || '',
            is_available: initialData.is_available || false,
    });
    }, [initialData]);

    useEffect(() => {
        // Validar si todos los campos están llenos y son válidos
        const isValid = formData.name && formData.sport;
        setIsFormValid(isValid);
    }, [formData]);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
        if (name==="price") {
            setDisplayPrice(value);
        }
    };

    // only for price formatting we add handleBlur and handleFocus
    const handleBlur = () => {
        setDisplayPrice(formatCurrency(displayPrice, 'es-CL', 'currency','CLP',0,0));
    };

    const handleFocus = () => {
        setDisplayPrice(formData.price);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const formDataParsed = {
            ...formData,
            min_players: 2,
            max_players: 4,
        }
        onSubmit(formDataParsed);
    };

    const availableCourts = ['Cancha 1', 'Cancha 2', 'Cancha 3'];
    //filter already created courts
    const filteredCourts = availableCourts.filter(
        (court) => !courts.some((createdCourt) => createdCourt.name === court)
      );

    return (
        <Paper elevation={3} style={{ padding: 16, marginTop: 16 }}>
        <Typography variant="h6" gutterBottom>
            {initialData.id ? `Editar ${initialData.name}` : 'Crear Cancha'}
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate autoComplete="off">
            <Grid container spacing={2}>
            <Grid item xs={12}>
                <TextField
                label="Deporte"
                name="sport"
                select
                fullWidth
                value={formData.sport}
                onChange={handleChange}
                required
                >
                <MenuItem value="Padel">Padel</MenuItem>
                </TextField>
            </Grid>
            {
                !initialData.id ?
                    <Grid item xs={12}>
                        <TextField
                        label="Nombre cancha"
                        name="name"
                        select
                        fullWidth
                        value={formData.name}
                        onChange={handleChange}
                        required
                        defaultValue={formData.name}
                        >
                        
                        {
                            filteredCourts.map((court) => (
                            <MenuItem key={court} value={court}>
                                {court}
                            </MenuItem>
                            ))
                        }
                        </TextField>
                    </Grid>
                    : ""
            }
            
            <Grid item xs={12}>
                <TextField
                label="Disponible para arriendo"
                name="is_available"
                select
                fullWidth
                value={formData.is_available}
                onChange={handleChange}
                required
                >
                <MenuItem value={true}>Sí</MenuItem>
                <MenuItem value={false}>No</MenuItem>
                </TextField>
            </Grid>
            
            {/*
            <Grid item xs={12}>
                <TextField
                label="Jugadores mínimos"
                name="min_players"
                type="number"
                fullWidth
                value={formData.min_players}
                onChange={handleChange}
                required
                inputProps={{ min: 2 }}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                label="Jugadores máximos"
                name="max_players"
                type="number"
                fullWidth
                value={formData.max_players}
                onChange={handleChange}
                required
                inputProps={{ min: 2 }}
                />
            </Grid>
            */}
            <Grid item xs={12}/>
                    <Grid item xs={12}>
                    { 
                    arrayAlerts.map((alert) => (
                    <Alert severity="warning">{alert}</Alert>
                    ))
                    }
            </Grid>


            <Grid item xs={12} container justifyContent="flex-end" spacing={2}>
                <Grid item>
                <Button variant="contained" color="primary" 
                    type="submit"
                    disabled={!isFormValid}
                    >
                    Guardar
                </Button>
                </Grid>
                <Grid item>
                <Button variant="outlined" color="secondary" onClick={onCancel}>
                    Cancelar
                </Button>
                </Grid>
            </Grid>
            </Grid>
        </Box>
        </Paper>
    );
};

export default CourtForm;


