import * as React from 'react';
import { Menu } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SportsTennisIcon from '@mui/icons-material/SportsTennis';
import InsightsIcon from '@mui/icons-material/Insights';
import WhatsappIcon from '@mui/icons-material/WhatsApp';
import Divider from '@mui/material/Divider';
import { Link } from 'react-router-dom';
import LogOutButton from './LogOutButton';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';


export default function MenuBar() {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const [isMobile, setIsMobile] = React.useState(false)
  const [whatsappUrl, setWhatsappUrl] = React.useState('')

  React.useEffect(() => {
    const checkDevice = () => {
      setIsMobile(window.innerWidth <= 768)
    }

    // Check on mount
    checkDevice()

    // Add event listener for window resize
    window.addEventListener('resize', checkDevice)

    // Cleanup
    return () => window.removeEventListener('resize', checkDevice)
  }, [])

  React.useEffect(() => {
    const phoneNumber = process.env.REACT_APP_CONTACT_PHONE; 
    const message = encodeURIComponent('Hola! Tengo un par de dudas o sugerencias sobre Matchplay') // Replace with your default message

    if (isMobile) {
      setWhatsappUrl(`whatsapp://send?phone=${phoneNumber}&text=${message}`)
    } else {
      setWhatsappUrl(`https://web.whatsapp.com/send?phone=${phoneNumber}&text=${message}`)
    }
  }, [isMobile])

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleWhatsAppClick = () => {
        window.open(whatsappUrl, '_blank')
      };

    return (
        <div>
        <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={handleMenuOpen}
              sx={{
                marginRight: '36px',
              }}
            >
              <MenuIcon />
            </IconButton>
        <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
                <Link to={`/courts`} style={{ textDecoration: 'inherit', color: 'inherit' }} key='courts'>
                  <ListItemButton onClick={handleMenuClose}>
                    <ListItemIcon>
                      <SportsTennisIcon />
                    </ListItemIcon>
                    <ListItemText primary="Mis Canchas" />
                  </ListItemButton>
                </Link>
                
                <Link to={`/dayview`} style={{ textDecoration: 'inherit', color: 'inherit' }} key='dayview'>
                  <ListItemButton onClick={handleMenuClose}>
                    <ListItemIcon>
                      <EventAvailableIcon />
                    </ListItemIcon>
                    <ListItemText primary="Disponibilidad" />
                  </ListItemButton>
                </Link>
                
                <Link to={`/massiveactions`} style={{ textDecoration: 'inherit', color: 'inherit' }} key='massiveactions'>
                  <ListItemButton onClick={handleMenuClose}>
                    <ListItemIcon>
                      <AutoAwesomeMotionIcon />
                    </ListItemIcon>
                    <ListItemText primary="Configurar precios" />
                  </ListItemButton>
                </Link>

                <Link to={`/insights`} style={{ textDecoration: 'inherit', color: 'inherit' }} key='insights'>
                  <ListItemButton onClick={handleMenuClose}>
                    <ListItemIcon>
                      <InsightsIcon />
                    </ListItemIcon>
                    <ListItemText primary="Insights" />
                  </ListItemButton>
                </Link>

                <Link to={`/profile`} style={{ textDecoration: 'inherit', color: 'inherit' }} key='profile'>
                  <ListItemButton onClick={handleMenuClose}>
                    <ListItemIcon>
                      <AccountCircleIcon />
                    </ListItemIcon>
                    <ListItemText primary="Mi Perfil" />
                  </ListItemButton>
                </Link>

                <ListItemButton onClick={handleWhatsAppClick}>
                    <ListItemIcon>
                      <WhatsappIcon />
                    </ListItemIcon>
                    <ListItemText primary="Dudas y sugerencias" />
                  </ListItemButton>

              <Divider sx={{ my: 1 }} />
              <MenuItem onClick={handleMenuClose}>
                <LogOutButton />
              </MenuItem>
              
            </Menu>
        </div>
    )
}