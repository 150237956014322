import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material';
import { format, set, toDate } from 'date-fns';
import { formatCurrency } from '../utils/currency';
import { MenuItem, TextField } from '@mui/material';
import { updateData } from '../utils/api';
import { useAuth } from '../hooks/useAuth';

function ReservationEditDialog({ open, onClose, reservation, mainReservations, setReservations }) {
  
  const [payStatus, setPayStatus] = useState(reservation.is_paid);

  useEffect(() => {
    if (open) {
      setPayStatus(reservation.is_paid);
    }
  }, [open, reservation.is_paid]);

    const API_URL = process.env.REACT_APP_API_URL;
    const user = useAuth().userData;

    const handleClose = (e) => {
        e.stopPropagation();
        onClose();
    };

    const handleReservationUpdate = async (reservationId, body) => {
        try {
          const response = await updateData(
            `${API_URL}/reservations/${reservationId}/`,
            user.token,
            body
          );
          if (response.id === reservationId) {
            setReservations(
              mainReservations.map(r => r.id === reservationId ? response : r)
            );
            setPayStatus(response.is_paid);
          }
          return true; // Indicate success
        } catch (error) {
          console.error('Error updating reservation:', error);
          return false; // Indicate failure
        }
    };

    const handleAction = async (e, reservationId, fieldsChange) => {
        e.preventDefault(); // Prevent default form submission if it's a form button
        const success = await handleReservationUpdate(reservationId, fieldsChange);
        if (success) {
          handleClose(e);
        }
    };

    const localStartDateTime = format(toDate(reservation.start_dttm, 
    { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone }),
    'dd MMM HH:mm');

    const localEndDateTime = format(toDate(reservation.end_dttm,
    { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone }),
    'dd MMM HH:mm');

    return (
    <Dialog open={open} onClose={handleClose} >
        <DialogTitle>Detalles de la Reserva</DialogTitle>
        <DialogContent>
        <Typography>Creador reserva: {reservation.owner_name.replace('-', ' ')}</Typography>
        <Typography>Inicio: {localStartDateTime}</Typography>
        <Typography>Fin: {localEndDateTime}</Typography>
        <Typography>Cancha: {reservation.court_name}</Typography>
        <Typography>Precio: {formatCurrency(reservation.price, 'es-CL', 'currency','CLP',0,0)}</Typography>
        <TextField
                    required
                    fullWidth
                    select
                    sx={{ mt: 2 }}
                    name="payStatus"
                    label="Estado del Pago"
                    id={"payStatus"+reservation.id}
                    value={payStatus}
                    onChange={(e) => setPayStatus(e.target.value)}
                    >
                            <MenuItem key={1} value={true}>
                                Pagado
                            </MenuItem>
                            <MenuItem key={2} value={false}>
                                Por Pagar
                            </MenuItem>           
        </TextField>
        {/* Add more reservation details here */}
        </DialogContent>
        <DialogActions>
        <Button onClick={handleClose}>Cerrar</Button>
        <Button onClick={(e) => handleAction(e, 
            reservation.id, {'is_cancelled':true, 'is_paid':reservation.is_paid})}>
                Cancelar reserva
        </Button>
        <Button onClick={(e) => handleAction(e, 
            reservation.id, {'is_cancelled':reservation.is_cancelled, 'is_paid': payStatus})}>Guardar cambios</Button>
        </DialogActions>
    </Dialog>
    );
}

export default ReservationEditDialog;