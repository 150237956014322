import IconButton from '@mui/material/IconButton';
import SaveIcon from '@mui/icons-material/Save';
import { Grid, TextField, Box, MenuItem, Alert } from '@mui/material';
import { useEffect, useState } from "react";
import { useAuth } from "../hooks/useAuth";
import { updateData } from '../utils/api';

import TextInputGoogleMaps from './TextInputGoogleMaps';
import { bankAccountTypes, chileanBanks, validateEmail, validateNumber, validatePhone } from '../utils/formValidators';

const EditClub = ({ setSeverity, setMessage, setActivateAlert }) => {

    const [clubChange, setClubChange] = useState(false);

    const club = useAuth().userData.clubs[0];
    const user = useAuth().userData;

    const { changeUserData } = useAuth();
    const [form, setForm] = useState({ ...club });

    const [formatValid, setFormatValid] = useState(false);
    const [arrayAlerts, setArrayAlerts] = useState([]);
    
    // on form change, check if every field is valid and return an array of alert texts
    useEffect(() => {
      setFormatValid(
        validateEmail(form.club_email) &&
        validatePhone(form.club_number,"CL") &&
        validateNumber(form.bank_account_number)
      )
      setArrayAlerts(
        [
          [!validateEmail(form.club_email), "Email inválido"],
          [!validatePhone(form.club_number,"CL"), "Número de teléfono inválido, ej: +56912345678"],
          [!validateNumber(form.bank_account_number), "Número de cuenta bancaria debe ser solo números"]
        ].reduce((acc, alert) => {
          if (alert[0]) {
            return [...acc, alert[1]];
          }
          return acc;
        }, [])
      );

    },[form]);


    const handleSubmit = async (event) => {
      event.preventDefault();
      
      const API_URL = process.env.REACT_APP_API_URL;
      const updateResponse = await updateData(`${API_URL}/clubs/${club.id}/`, user.token, {...form});
    
      changeUserData(
        {
          ...user,
          clubs: [{...form}]
        }
        );

      setClubChange(false);

      if (updateResponse.id) {
        setSeverity("success");
        setMessage("Club actualizado correctamente");
        setActivateAlert(true);
      }

    }

    return (
        
        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              
            
              
              
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  onChange={(e) => {
                    setForm({
                      ...form,
                      name: e.target.value
                    });
                    setClubChange(true);
                  }}
                  id="club_name"
                  label="Nombre del Club"
                  name="club_name"
                  autoComplete="club_name"
                  defaultValue={form.name}
                />
              </Grid>

              <TextInputGoogleMaps 
                setForm={setForm}
                form={form}
                setClubChange={setClubChange}
              />

              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  onChange={(e) => {
                    setForm({
                      ...form,
                      club_email: e.target.value
                    });
                    setClubChange(true);
                  }}
                  name="club_email"
                  label="Email Club"
                  type="club_email"
                  id="club_email"
                  autoComplete="club_email"
                  defaultValue={form.club_email}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  onChange={(e) => {
                    setForm({
                      ...form,
                      club_number: e.target.value
                    });
                    setClubChange(true);
                  }}
                  name="club_phone"
                  label="Número de telefono del Club"
                  type="club_phone"
                  id="club_phone"
                  autoComplete="club_phone"
                  defaultValue={form.club_number}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  select
                  onChange={(e) => {
                    setForm({
                      ...form,
                      bank: e.target.value
                    });
                    setClubChange(true);
                  }}
                  name="bank"
                  label="Banco"
                  type="bank"
                  id="bank"
                  autoComplete="bank"
                  defaultValue={form.bank}
                >
                {
                    chileanBanks.map((bankName) => (
                    <MenuItem key={bankName} value={bankName}>
                        {bankName}
                    </MenuItem>
                    ))
                }

                </TextField>
              
              </Grid>
                
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  select
                  onChange={(e) => {
                    setForm({
                      ...form,
                      bank_account_type: e.target.value
                    });
                    setClubChange(true);
                  }}
                  name="bank_account_type"
                  label="Tipo de cuenta"
                  type="bank_account_type"
                  id="bank_account_type"
                  autoComplete="bank_account_type"
                  defaultValue={form.bank_account_type}
                >
                  {
                    bankAccountTypes.map((accountType) => (
                      <MenuItem key={accountType} value={accountType}>
                          {accountType}
                      </MenuItem>
                    ))
                  }
                </TextField>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  inputProps={{ min: 0 }}
                  onChange={(e) => {
                    setForm({
                      ...form,
                      bank_account_number: e.target.value
                    });
                    setClubChange(true);
                  }}
                  name="bank_account_number"
                  label="Número de cuenta bancaria"
                  id="bank_account_number"
                  autoComplete="bank_account_number"
                  defaultValue={form.bank_account_number}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  inputProps={{ min: 0 }}
                  onChange={(e) => {
                    setForm({
                      ...form,
                      bank_account_owner_rut: e.target.value
                    });
                    setClubChange(true);
                  }}
                  name="bank_account_owner_rut"
                  label="Rut asociado a la cuenta bancaria"
                  id="bank_account_owner_rut"
                  autoComplete="bank_account_owner_rut"
                  defaultValue={form.bank_account_owner_rut}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  inputProps={{ min: 0 }}
                  onChange={(e) => {
                    setForm({
                      ...form,
                      bank_account_owner_name: e.target.value
                    });
                    setClubChange(true);
                  }}
                  name="bank_account_owner_name"
                  label="Nombre asociado a la cuenta bancaria"
                  id="bank_account_owner_name"
                  autoComplete="bank_account_owner_name"
                  defaultValue={form.bank_account_owner_name}
                />
              </Grid>
            

              <Grid item xs={12}/> 

              </Grid>

              <Grid item xs={12}>

                { clubChange ? arrayAlerts.map((alert) => (
                  <Alert severity="error">{alert}</Alert>
                ))
                : ""}
                
              </Grid>
              
              <Grid container spacing={2}>
                { clubChange ? 
                <IconButton
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                size='small'
                disabled={!formatValid}
                sx={{ mt: 3, mb: 2 }}
                >
                    <SaveIcon/>
                    Guardar cambios
                </IconButton> : ""
              }
              </Grid>

          </Box>
        
    )

};

export default EditClub;
