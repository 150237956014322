import * as React from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import CreateClub from './CreateClub';
import EditClub from './EditClub';

import { useAuth } from "../hooks/useAuth";
import { useState } from 'react';

import SnackbarAlert from './SnackBarAlert';

const MyClub = () => {
    
    const club = useAuth().userData.clubs

    const [activateAlert, setActivateAlert] = useState(false);
    const [severity, setSeverity] = useState("success");
    const [message, setMessage] = useState("default message");

    const handleCloseAlert = () => {
      setActivateAlert(false);
    }
   
    return (
        <Grid item xs={12}>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
            <React.Fragment>
        <Typography component="h2" variant="h6" color="primary" gutterBottom>
            Mi Club
        </Typography>
        { 
            (club.length>0) ? 
                <EditClub 
                    setSeverity={setSeverity}
                    setMessage={setMessage}
                    setActivateAlert={setActivateAlert}
                /> : 
                <CreateClub 
                    setSeverity={setSeverity}
                    setMessage={setMessage}
                    setActivateAlert={setActivateAlert}
                />  
        }
        <SnackbarAlert
                    open={activateAlert}
                    onClose={handleCloseAlert}
                    severity={severity}  // Puedes cambiar a "error", "warning", "info"
                    message={message}
                    ms={6000}  // Duración en milisegundos (6000ms = 6s)
                    vertical={'bottom'}
                    horizontal={'right'}
        />
    </React.Fragment>
            </Paper>
        </Grid>
    );

}

export default MyClub;