
import DayColumn from "../Components/DayColumn"
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { ThemeProvider } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { Stack } from "@mui/material";
import DateFilter from "../Components/DateFilter";
import CheckboxesTags from "../Components/CheckBoxesTags";
import { useAuth } from "../hooks/useAuth";
import { useEffect, useState } from "react";
import { getUTCDateTime, dateToString, timeToString, 
    tomorrowToString, getLocalDateTime, dateTimeToString, nowToString  } from "../utils/datef";
import React from "react";
import DayViewActions from "../Components/DayViewActions";
import { deleteData, postDataToken } from '../utils/api';


import Typography from '@mui/material/Typography';

import SnackbarAlert from "../Components/SnackBarAlert";

import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';

import { getData } from '../utils/api'
import { toDate, format } from 'date-fns-tz';

const defaultTheme = createTheme();


export default function DayView() {
    const user = useAuth().userData;
    const clubs = user.clubs ? [...user.clubs] : [];
    
    const initCourts = clubs.length>0 ? 
        [...clubs[0].courts] : []
    initCourts.sort((a, b) => a.id - b.id);
    const [courts, setCourts] = useState(initCourts);
    
    const [selectedCourts, setSelectedCourts] = useState([...courts].map((court) => ({name: court.name, id: court.id}))); // all courts selected by default

    const [selectedDate, setSelectedDate] = useState(nowToString());
    const [tempDate, setTempDate] = useState(null);
    const [open, setOpen] = useState(false);

    const [selectedBlocks, setSelectedBlocks] = useState([]);

    const [actions, setActions] = useState([]);
  
    const [nextId, setNextId] = useState(0);
    const [lastActionIndex, setLastActionIndex] = useState(null);
    const { changeUserData } = useAuth();

    const [reservations, setReservations] = useState([])

    const [activateAlert, setActivateAlert] = useState(false);
    const [severity, setSeverity] = useState("success");
    const [message, setMessage] = useState("default message");

    const API_URL = process.env.REACT_APP_API_URL;

    useEffect(() => {
        const fetchReservations = async () => {
            try {
                const localStartDateTime = toDate(selectedDate+"T00:00:00",
                    { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone })
                const localEndDateTime = toDate(selectedDate+"T23:59:59",
                    { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone })
                const utcStartDateTime = localStartDateTime.toISOString();
                const utcEndDateTime = localEndDateTime.toISOString();
                const response = await getData(`${API_URL}/reservations/`
                    +`?start_dttm=${utcStartDateTime}`
                    +`&&end_dttm=${utcEndDateTime}`
                    +`&&is_cancelled=False`
                    ,
                    user.token);
                setReservations(response.results);
            return response
            } catch  (error) {
                console.error('Error fetching reservations:', error);
            }
        }
        fetchReservations();
    }, [selectedDate])

    const handleSelectorChange = (event, newValue) => {
        setSelectedCourts(newValue);
    }
    
    const handleDateChange = (event) => {
        const { name, value } = event.target;  
        setTempDate(value);
        if (actions.length > 0 || selectedBlocks.length > 0) {
            setOpen(true);
        } else {
            setSelectedDate(value);
        }
    };

    const handleConfirm = () => {
        setSelectedDate(tempDate);
        setOpen(false);
    };

    const handleCancel = () => {
        setTempDate(null);
        setOpen(false);
    };

    const handleBlockClick = (hour, court_id) => {
        setSelectedBlocks((prev) =>
        // check if the hour is already selected, if so remove it, otherwise add it   
        prev.some( b => b.hour === hour && b.court_id === court_id)
            ? prev.filter((block) => !(block.hour === hour && block.court_id === court_id))
            : [...prev, {hour: hour, court_id: court_id}]
        );
        };
    
    const handleBlockAction = async () => {
        const API_URL = process.env.REACT_APP_API_URL;
        
        // create blocks for each selected block and wait for all of them to be created
        let createdBlocks = await Promise.all(selectedBlocks.map(async (block) => {
            const hours = block.hour.split(" - ")[0].split(":")[0];
            const minutes = block.hour.split(" - ")[0].split(":")[1];
            const selectedDateTimeStr = selectedDate+"T"+
                hours.padStart(2, '0')+":"+minutes.padStart(2, '0')+":00";
            const dbDateTime = getUTCDateTime(selectedDateTimeStr);
            const blockData = {
                court: courts.find(c => c.id === block.court_id).url,
                start_date: dateToString(dbDateTime),
                end_date: dateToString(dbDateTime),
                start_time: timeToString(dbDateTime),
                end_time: timeToString(dbDateTime),
                type: "Block",
                description: "block",
            };
            const newBlock = await postDataToken(`${API_URL}/blocks/`, user.token, blockData);
            return newBlock;
        }
        ));

        //if all blocks were created, change court state
        if (createdBlocks.length === selectedBlocks.length) {
            const newCourtState = courts.map((court) => {
                const courtCreatedBlocks = createdBlocks.filter((block) => block.court === court.url);
                return {
                    ...court,
                    blocks: [...court.blocks,
                        ...courtCreatedBlocks
                    ]
                }
            }
            );
            
            setCourts(prevCourts => newCourtState);
            changeUserData( 
                {
                    ...user,
                    clubs: [
                        {...clubs[0],
                            courts: newCourtState
                        }
                    ]
                } 
            );
            setActivateAlert(true);
            setSeverity("success");
            setMessage("Bloqueos creados con éxito");

            
        } else {
            setActivateAlert(true);
            setSeverity("error");
            setMessage("Error al crear bloqueos");
        }
        setSelectedBlocks([]);
    } 

    const handleUnblockClick = async (hour, court_id) => {
        const newBlocks = courts.map((court) => {
            const newCourtBlocks = court.blocks.filter((b) => {
                const hours = hour.split(" - ")[0].split(":")[0].padStart(2, '0');
                const minutes = hour.split(" - ")[0].split(":")[1].padStart(2, '0');
                return !(dateTimeToString(getLocalDateTime(b.start_date + "T" + b.start_time)) === 
                selectedDate + "T" + hours + ":" + minutes + ':00' && court_id === court.id);
            });
            
            return {
                ...court,
                blocks: newCourtBlocks
            }
        });
        const blockToDelete = courts.find((court) => court.id === court_id).blocks.find((b) => {
            const hours = hour.split(" - ")[0].split(":")[0].padStart(2, '0');
            const minutes = hour.split(" - ")[0].split(":")[1].padStart(2, '0');
            return dateTimeToString(getLocalDateTime(b.start_date + "T" + b.start_time)) === 
            selectedDate + "T" + hours + ":" + minutes + ':00';
        });
       
        const deletedBlock = await deleteData(blockToDelete.url, user.token);
        if (deletedBlock.status===204) {
            setCourts(newBlocks);
            changeUserData(
                {
                    ...user,
                    clubs: [
                        {...clubs[0],
                            courts: newBlocks
                        }
                    ]
                } 
            );
            setActivateAlert(true);
            setSeverity("success");
            setMessage("Bloqueo eliminado con éxito");
        } else {
            setActivateAlert(true);
            setSeverity("error");
            setMessage("Error al eliminar bloqueo");
        }
    }

    
    const handleClose = () => {
        setActivateAlert(false);
    }


    React.useEffect(() => {
        setActions([]);
        setSelectedBlocks([]);
    }
    , [selectedDate]);

    return (
        
        <ThemeProvider theme={defaultTheme}>

            <Container component="main" maxWidth="l">
                <CssBaseline />

            <Paper elevation={3} sx={{ padding: 2, margin: 2 }}>
                <Stack 
                    spacing={2} 
                    direction={{ xs: 'column', sm: 'row' }} 
                    justifyContent="center"
                    sx={{
                        marginTop: 0,
                        
                    }}
                >
                    <DateFilter 
                        selectedDate={selectedDate} 
                        handleChange={handleDateChange}
                        handleCancel={handleCancel}
                        handleConfirm={handleConfirm}
                        open={open}
                        tempDate={tempDate}
                        />
                    <CheckboxesTags options={courts.map((court) => ({name: court.name, id: court.id}))} 
                        selectedOptions={selectedCourts} 
                        handleChange={handleSelectorChange}/>
                    <TextField label="Deporte" name="sport"
                        select
                        value={"Padel"}
                >
                    <MenuItem value="Padel">Padel</MenuItem>
                </TextField>
                </Stack>
            </Paper>
            
            <Paper elevation={3} sx={{ padding: 2, margin: 2 }}>
                <React.Fragment>
                <Typography component="h2" variant="h6" color="primary" gutterBottom>
                Acciones
                </Typography>

            <DayViewActions 
                actions={actions}
                setActions={setActions}
                courts={courts}
                setCourts={setCourts}
                selectedDate={selectedDate}
                courtDayPrice={courtDayPrice}
                setLastActionIndex={setLastActionIndex}
                selectedBlocks={selectedBlocks}
                handleBlockAction={handleBlockAction}
                setReservations={setReservations}
                setSelectedBlocks={setSelectedBlocks}
            />

            <SnackbarAlert
                        open={activateAlert}
                        onClose={handleClose}
                        severity={severity}  // Puedes cambiar a "error", "warning", "info"
                        message={message}
                        ms={6000}  // Duración en milisegundos (6000ms = 6s)
                        vertical='bottom'
                        horizontal='right'
                    />
            </React.Fragment>
            </Paper>
                
            <Box sx={{
                    
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    margin: 2,
                    padding: 2,
                }}
            >
                <Grid 
                    container 
                    spacing={2}
                    sx={{
                        overflowX: { xs: 'scroll', md: 'hidden' }, // horizontal scroll only on small screens
                        flexWrap: { xs: 'nowrap', md: 'wrap' }, // Avoid wrap on small screens
                    }}
                    >
                    { //first we filter based on selector, and later we map the remaining courts
                    courts.filter((court) => selectedCourts.map((sc) => sc.name).includes(court.name)).map((court) => (
                        <Grid item xs={6} md={2} key={court.name}>
                            <DayColumn 
                                court={court}
                                courtName={court.name} 
                                courtPrice={courtDayPrice(court.price, court.prices, selectedDate)}   
                                actions={actions}
                                nextId={nextId}
                                actionDay={selectedDate}
                                blocks={court.blocks.filter((block) => 
                                    dateToString(getLocalDateTime(block.start_date+"T"+block.start_time)) 
                                    === selectedDate)}
                                selectedBlocks={selectedBlocks.filter((block) => block.court_id === court.id).map((block) => block.hour)}
                                handleBlockClick={handleBlockClick}
                                handleUnblockClick={handleUnblockClick}
                                lastActionIndex={lastActionIndex}
                                setLastActionIndex={setLastActionIndex}
                                setActions={setActions}
                                reservations={reservations.filter(
                                    reservation => reservation.court===court.id && reservation.is_cancelled===false)}
                                mainReservations={reservations}
                                setReservations={setReservations}
                            />
                        </Grid>
                    ))}
                </Grid>
            </Box>
            </Container>
        </ThemeProvider>
        )
}

// function to get the price of a court for a given day
const courtDayPrice = (courtPrice, courtPrices, day) => {
    const price = courtPrices.find(cp => cp.start_date === day);
    if (price) {
        return price.price;
    } else {
        return courtPrice;
    }
}