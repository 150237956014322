

const fetchWithToken = async (url, options = {},tokeninput, body) => {
    // Obtener el token desde localStorage
    const token = tokeninput;
  
    if (!token) {
      throw new Error('No token found');
    }
  
    // Configurar los encabezados, incluyendo el token
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Token ${token}`,
      ...options.headers,
    };
    
  
    // Configurar las opciones de la solicitud
    const fetchOptions = {
      ...options,
      headers,
      body: JSON.stringify(body)
    };
  
    try {


      // Realizar la solicitud a la API
      const response = await fetch(url, fetchOptions);
  
      // Verificar si la respuesta es exitosa
      if (!response.ok) {
        throw new Error(`Error: ${response.status} ${response.statusText}`);
      }

      if (fetchOptions.method === 'DELETE') {
        return {status: response.status};
      }
  
      // Parsear la respuesta como JSON
      const data = await response.json();
      return data;
    } catch (error) {
      // Manejar errores
      console.error('Error fetching data:', error);
      throw error;
    }
  };
  
export const getData = async (url,token) => {
    try {
      const data = await fetchWithToken(url, {
        method: 'GET',
      },token);
  
      return data;
    } catch (error) {
      console.error('Error:', error);
    }
  };

export const updateData = async (url, token, body) => {
  try {
    const data = await fetchWithToken(url, {
      method: 'PUT',
    },token, body);

    return data;
  } catch (error) {
    console.error('Error:', error);
  }
};

export const postDataToken = async (url, token, body) => {
  try {
    const data = await fetchWithToken(url, {
      method: 'POST',
    },token, body);

    return data;
  } catch (error) {
    console.error('Error:', error);
  }
};

// only to register new users
export const postAPI = async (url,body) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    try {
        const response = await fetch(url, {
        method: "POST",
        body: JSON.stringify(body),
        headers: myHeaders,
        });
        
        //Verify if response has a JSON content
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.includes("application/json")) {
          const data = await response.json();
          return data;
      } else {
          // Maneja el caso cuando la respuesta no tiene JSON
          console.error("La respuesta no es JSON");
          const text = await response.text();
          return { text };
      }
    } catch (e) {
        return e;
    }
};

export const deleteData = async (url, token) => {
  try {
    const data = await fetchWithToken(url, {
      method: 'DELETE',
    },token, '{}');

    return data;
  } catch (error) {
    console.error('Error:', error);
  }
};