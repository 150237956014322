import React, { useState, useCallback, useEffect } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { toDate } from 'date-fns-tz';
import ReservationEditDialog from './ReservationEditDialog';

const getBlockOptions = (defaultFunction, reservedFunction) => ({
  default: {
    backgroundColor: 'background.paper',
    borderColor: 'grey.300',
    backgroundImage: 'none',
    handleClick: defaultFunction,
  },
  selected: {
    backgroundColor: 'primary.light',
    borderColor: 'primary.main',
    backgroundImage: 'none',
    handleClick: defaultFunction,
  },
  blocked: {
    backgroundColor: 'none',
    borderColor: 'grey.300',
    backgroundImage: 'repeating-linear-gradient(45deg, #F5F5F5 0, #F5F5F5 10px, #E0E0E0 10px, #E0E0E0 20px)',
    handleClick: () => console.log('blocked'),
  },
  reservedFirst: {
    backgroundColor: 'lime',
    borderColor: 'lime',
    backgroundImage: 'none',
    handleClick: reservedFunction,
  },
  reservedContinue: {
    backgroundColor: 'lime',
    borderColor: 'lime',
    backgroundImage: 'none',
    handleClick: reservedFunction,
  },
});

const TimeBlock = ({ hour, selected, onClick, blocked, id, 
  onUnblockClick, reservation, mainReservations, setReservations}) => {
  
  const [openReservationDialog, setOpenReservationDialog] = useState(false);

  const [openCreateDialog, setOpenCreateDialog] = useState(false);

  const handleDialogCreateClose = useCallback(() => {
    setOpenCreateDialog(false);
  }, []);

  const handleSelect = () => onClick(hour.label, id);
  
  const handleReservedClick = useCallback(() => {
    setOpenReservationDialog(true);
  }, []);

  const handleDialogClose = useCallback(() => {
    setOpenReservationDialog(false);
  }, []);

  const blockOptions = getBlockOptions(handleSelect, handleReservedClick);


  const getBlockType = () => {
    if (blocked) return 'blocked';
    if (reservation?.id && 
      // Check if the block is the first one of the reservation
      toDate(reservation.start_dttm, 
        { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone })
      .getTime() === hour.value.getTime()) return 'reservedFirst';
    if (reservation?.id) return 'reservedContinue';
    if (selected) return 'selected';
    return 'default';
  };

  const blockType = getBlockType();

  return (
    <Box
      sx={{
        position: 'relative',
        border: '1px solid',
        borderColor: selected ? 'primary.main' : 'grey.300',
        padding: 2,
        cursor: 'pointer',
        minHeight: 80,
        backgroundColor: blockOptions[blockType].backgroundColor,
        backgroundImage: blockOptions[blockType].backgroundImage,
      }}
      onClick={blockOptions[blockType].handleClick}
      //onClick={blocked || reservation?.id ? () => "" : () => onClick(hour.label, id)}
    >
      <Typography>
      {hour.label}
      </Typography>
      <Typography variant='body2'>
      {blockType === 'reservedFirst' ? 
        reservation?.owner_name.replace('-', ' ')
        : ""}
      </Typography>
      { blockType === 'reservedFirst' && !reservation?.is_paid ?
      <Typography variant='body2'
        sx={{
          position: 'absolute',
          bottom: 0,
          right: 0,
          padding: '1px 1px', // Reduce el padding para hacer el botón más compacto
          minWidth: 'auto', // Evita que el botón tenga un ancho mínimo predeterminado
          fontSize: '0.75rem',
        }}
      >
      <>
        {!reservation?.is_paid && <span style={{ color: 'red' }}>POR PAGAR</span>}
      </>
      </Typography>
      : ""
    }
      
    {
      blocked ?
    
    <Button
        onClick={() => onUnblockClick(hour.label, id)}
        variant="contained"
        size="small"
        sx={{
          position: 'absolute',
          top: 40,
          right: 0,
          padding: '1px 1px', // Reduce el padding para hacer el botón más compacto
          minWidth: 'auto', // Evita que el botón tenga un ancho mínimo predeterminado
          fontSize: '0.5rem',
        }}
        >
        Desbloquear
    </Button>
    : ""
    }
    { reservation?.id &&
    <ReservationEditDialog
      open={openReservationDialog}
      onClose={handleDialogClose}
      reservation={reservation}
      mainReservations={mainReservations}
      setReservations={setReservations}
    />
    }
    </Box>
  );
};

export default TimeBlock;
