'use client'

import React, { useEffect, useState } from 'react'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'

import { 
Toolbar, 
Typography, 
Select, 
MenuItem, 
Grid,
Alert,
Dialog,
DialogTitle,
DialogContent,
DialogActions,
Button,
TextField,
} from '@mui/material'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import SnackbarAlert from '../Components/SnackBarAlert'
import EditConfig from '../Components/EditPriceConfig'
import { useAuth } from '../hooks/useAuth'
import { getData, postDataToken, deleteData } from '../utils/api'
import { dateToString } from '../utils/datef'
import { parseISO, addHours } from 'date-fns';

moment.locale('es')
moment.updateLocale('es', {
    week: { dow: 1 } // dow: 1 significa que la semana comienza el lunes
  });
const localizer = momentLocalizer(moment)

export default function PriceConfigurationPage() {
    const [configurations, setConfigurations] = useState( [
        { id: 0, sport: "Padel", type: 'default', name: 'Cargando...', intervals: [], color: '#F5F5F5'},
        { id: 1, sport: "Padel", type: 'weekend', name: 'Cargando...', intervals: [], color: '#FF5733'},
        { id: 2, sport: "Padel", type: 'holiday', name: 'Cargando...', intervals: [], color: '#33FF57'},
        { id: 3, sport: "Padel", type: 'other', name: 'Cargando...', intervals: [], color: '#3357FF'},
    ]);
    // days in which there is applied a special configuration, different from default
    const [datesConfiguration, setDatesConfiguration] = useState([
    ]);

    const [selectedSport, setSelectedSport] = useState('Padel')
    const [selectedDate, setSelectedDate] = useState(null)
    const [selectedConfigId, setSelectedConfigId] = useState(0)

    const [openAlert, setOpenAlert] = useState(false)
    const [alertSeverity, setAlertSeverity] = useState('success')
    const [alertMessage, setAlertMessage] = useState('')
    const [isDefault, setIsDefault] = useState(configurations.find(
        config => config.type==='default').intervals.length===0)
    
    const [openDialog, setOpenDialog] = useState(false)

    const [data, setData] = useState([])
    const [dataDates, setDataDates] = useState([])
    
    const sports = ['Padel'] 

    const API_URL = process.env.REACT_APP_API_URL;
    const user = useAuth().userData;

    useEffect(() => {
    const fetchPriceConfigurations = async () => {
        // on render fetch the price configurations for the selected sport
        try {
        const response = await getData(`${API_URL}/priceconfigurations/?sport=${selectedSport}`, user.token);
        setData(response.results);
        
        const firstCourtConfigs = response.results.filter(config => config.court === response.results[0].court)
        // set first court as default. Any changes in configuration
        // will be applied to all of the courts
        setConfigurations(firstCourtConfigs);
        } catch (error) {
        console.error('Error fetching price configurations:', error);
        } 
    };
    fetchPriceConfigurations();

    }, [API_URL, user.token, selectedSport]);

    useEffect(() => {
        setIsDefault(configurations.find(
            config => config.type==='default').intervals.length===0)
        
        const fetchDatesConfigurations = async () => {
            try {
            const response = await getData(`${API_URL}/dateconfigurations/?sport=${selectedSport}`, user.token);
        
            setDataDates(response.results);
            setDatesConfiguration(response.results.filter(
                dateConfig => configurations.map(config => config.url).includes(dateConfig.price_configuration)
            )
            );
            } catch (error) {
            console.error('Error fetching dates configurations:', error);
            } 
        }
        fetchDatesConfigurations();
    }, [configurations])

    const handleSave = () => {

    

        // add dates configurations

        const createDatesConfigurations = async () => {
            // create a new configuration for the selected date
            // for every court

            try {
                // delete configurations for the selected date
                
                // all of the dates configurations of the DDBB for the selected date
                const databaseDates = dataDates.filter(
                    date => moment(date.date).isSame(selectedDate, 'day')
                )
                

                const promisesDelete = databaseDates.map(
                    date => deleteData(`${date.url}?sport=${selectedSport}`, user.token)
                )
                await Promise.all(promisesDelete)

                const selectedType = configurations.find(config => config.id === selectedConfigId).type
                let newConfig = null; // 'default' is the default configuration
       
                if (selectedType !== 'default') {
                const promises = data.filter(
                    config => config.type === selectedType).map(
                        config => postDataToken(`${API_URL}/dateconfigurations/`, user.token, {
                            price_configuration: config.url,
                            date: moment(selectedDate).format('YYYY-MM-DD'),
                        })
                    )
                const responses = await Promise.all(promises);
                setDataDates([
                    ...dataDates,
                    ...responses
                ]);
                newConfig = responses.filter(
                    // get only one of the responses. Only one court is needed
                    response => configurations.map(config => config.url).includes(response.price_configuration)
                )[0]
                }

                setDatesConfiguration([
                    // add new configuration
                    ...(newConfig ? [newConfig] : []),
                    // remove previous configuration
                    ...datesConfiguration.filter( 
                        date => !moment(date.date).isSame(selectedDate, 'day'))
                ]);
            
            } catch (error) {
                console.error('Error creating dates configurations:', error);
            }   
        }
        createDatesConfigurations();
 
        setOpenDialog(false);

      };

    const handleSportChange = (e) => {
        setSelectedSport(e.target.value)
        // agregar llamado a la api para traer solo la info de la configuración de precios del deporte seleccionado
    }

    const handleDateSelect = (slotInfo) => {
        setSelectedDate(slotInfo.start)
        // Here you would typically update the configuration for the selected date
        setSelectedConfigId(
            datesConfiguration.find(date => moment(date.date).isSame(slotInfo.start, 'day'))?.config_id || 
                configurations.find(config => config.type==='default').id
        )
        // open dialog even if there is no configuration intervals set
        setOpenDialog(true)
    }

    const handleAlertClose = () => {
        setOpenAlert(false)
    }

    return (
    <Grid item xs={12} md={12} lg={12}>
      
        <Toolbar>
          <Typography variant="h6" style={{ flexGrow: 1 }}>
            Configuración de Precio
          </Typography>
          <Select
            value={selectedSport}
            onChange={handleSportChange}
          >
            {sports.map((sport) => (
              <MenuItem key={sport} value={sport}>
                {sport}
              </MenuItem>
            ))}
          </Select>
        </Toolbar>
   
      
        <Calendar
          localizer={localizer}
          events={ 
            datesConfiguration.map(date => ({
                title: "$$$",
                color: configurations.find(config => config.url===date.price_configuration)?.color,
                allDay:true,
                start: addHours(parseISO(date.date), 12),
                end: addHours(parseISO(date.date), 12)
            }))
        }
          startAccessor="start"
          endAccessor="end"
          style={{ height: 400, marginTop: 10 }}
          onSelectSlot={handleDateSelect}
          onSelectEvent={handleDateSelect}
          selectable
          views={['month']}
          eventPropGetter={(event) => ({ style: { color: event.color, backgroundColor: "#F5F5F5", position: "center" } })}
        />

    <Dialog open={openDialog} onClose={ () => setOpenDialog(false) } fullWidth maxWidth="md">
        <DialogTitle>
          Seleccionar configuración de Precio para {selectedSport} el {moment(selectedDate).format('YYYY-MM-DD')}
        </DialogTitle>
        <DialogContent>
        <TextField
                    required
                    fullWidth
                    select
                    sx={{ mt: 2 }}
                    name="configuration"
                    label="Precio"
                    id="configuration"
                    value={selectedConfigId}
                    onChange={(e) => setSelectedConfigId(e.target.value)}
                    >
                        {
                            configurations.map((config) => (
                            <MenuItem key={config.id} value={config.id}>
                                {config.name}
                            </MenuItem>
                            ))
                        }
                    </TextField>
        </DialogContent>
        <DialogActions>
          <Button onClick={ () => setOpenDialog(false)} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleSave} color="primary" variant="contained">
            Guardar
          </Button>
        </DialogActions>
      </Dialog>

        {
        isDefault &&
        <Alert severity="error">
            Configura al menos "Precios por defecto" para que tus canchas de {selectedSport} estén disponibles para ser arrendadas
        </Alert>
        } 
        

    <EditConfig 
        configurations={configurations}
        setConfigurations={setConfigurations}
        selectedSport={selectedSport}
        selectedDate={selectedDate}
        isDefault={isDefault}
        setIsDefault={setIsDefault}
        setOpenAlert={setOpenAlert}
        setAlertSeverity={setAlertSeverity}
        setAlertMessage={setAlertMessage}
        data={data}
    />
      
      <SnackbarAlert 
        open={openAlert}
        severity={alertSeverity}
        message={alertMessage}
        onClose={handleAlertClose}
        ms={6000}
        vertical="bottom"
        horizontal="right"
      />
    </Grid>
    
  )
}