import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;



export default function CheckboxesTags({ options, selectedOptions, handleChange }) {

  
  return (
    <Autocomplete
      multiple
      id="checkboxes-tags-demo"
      options={options}
      disableCloseOnSelect
      onChange={handleChange}
      value={selectedOptions}
      getOptionLabel={(option) => option.name}
      sx={{
        minWidth: 200,  // Establece un ancho mínimo para el contenedor
        '& .MuiAutocomplete-input': {
            minWidth: 150,  // Asegura que el input tenga un ancho mínimo cuando está vacío
        },
    }}
      isOptionEqualToValue={(option, value) => option.id === value.id} // Compara por ID  
      renderOption={(props, option, { selected }) => {
        const { key, ...optionProps } = props;
        return (
          <li key={key} {...optionProps}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.name}
          </li>
        );
      }}
      
      renderInput={(params) => (
        <TextField {...params} label="Canchas" placeholder="" />
      )}
    />
  );
}

